import { render, staticRenderFns } from "./Reviewmind6.vue?vue&type=template&id=7cdda087&"
import script from "./Reviewmind6.vue?vue&type=script&lang=js&"
export * from "./Reviewmind6.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7cdda087')) {
      api.createRecord('7cdda087', component.options)
    } else {
      api.reload('7cdda087', component.options)
    }
    module.hot.accept("./Reviewmind6.vue?vue&type=template&id=7cdda087&", function () {
      api.rerender('7cdda087', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/dashboard/Reviewmind6.vue"
export default component.exports