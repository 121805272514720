var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "review-bg" },
    [
      _c("Navbar"),
      _c(
        "div",
        { staticClass: "review-body" },
        [
          _c("TopBar"),
          _c("div", { staticClass: "filter-bar boxarea" }, [
            _c("button", { on: { click: _vm.handleMove } }, [
              _vm._v(_vm._s(_vm.$t("reset_review_select")))
            ])
          ]),
          _c("section", { staticClass: "review-marketing" }, [
            _c("div", { staticClass: "marketing-left" }, [
              _c("div", { staticClass: "review-frame boxarea" }, [
                _c("h2", [_vm._v(_vm._s(_vm.$t("select_review_frame")))]),
                _c("div", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.reviewFont,
                          expression: "reviewFont"
                        }
                      ],
                      on: {
                        change: [
                          function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.reviewFont = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          _vm.handleReviewfont
                        ]
                      }
                    },
                    [
                      _c("option", { attrs: { value: "", disabled: "" } }, [
                        _vm._v("font select")
                      ]),
                      _c("option", { attrs: { value: "NOTO" } }, [
                        _vm._v("Noto Sans KR")
                      ]),
                      _c("option", { attrs: { value: "NANUM" } }, [
                        _vm._v("NanumSquare")
                      ])
                    ]
                  ),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.reviewType,
                          expression: "reviewType"
                        }
                      ],
                      on: {
                        change: [
                          function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.reviewType = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          _vm.handleReviewtype
                        ]
                      }
                    },
                    [
                      _c("option", { attrs: { value: "", disabled: "" } }, [
                        _vm._v(_vm._s(_vm.$t("type_select")))
                      ]),
                      _c("option", { attrs: { value: "square" } }, [
                        _vm._v(_vm._s(_vm.$t("rectangular_type")))
                      ]),
                      _c("option", { attrs: { value: "bedge" } }, [
                        _vm._v(_vm._s(_vm.$t("badge_type")))
                      ])
                    ]
                  )
                ])
              ]),
              _c("div", { staticClass: "review-color boxarea" }, [
                _c("div", { staticClass: "review-bgcolor" }, [
                  _c("h2", [_vm._v(_vm._s(_vm.$t("select_brand_color")))]),
                  _c(
                    "div",
                    [
                      _c("sketch-picker", {
                        model: {
                          value: _vm.colorObj,
                          callback: function($$v) {
                            _vm.colorObj = $$v
                          },
                          expression: "colorObj"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "review-font" }, [
                  _c("h2", [_vm._v(_vm._s(_vm.$t("select_font_color")))]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.fontColor,
                        expression: "fontColor"
                      }
                    ],
                    attrs: {
                      type: "radio",
                      id: "font-black",
                      name: "fontcolor",
                      value: "#111"
                    },
                    domProps: { checked: _vm._q(_vm.fontColor, "#111") },
                    on: {
                      change: function($event) {
                        _vm.fontColor = "#111"
                      }
                    }
                  }),
                  _c("label", { attrs: { for: "font-black" } }, [
                    _c("b", [
                      _c("span"),
                      _vm._v(" " + _vm._s(_vm.$t("black")) + " ")
                    ])
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.fontColor,
                        expression: "fontColor"
                      }
                    ],
                    attrs: {
                      type: "radio",
                      id: "font-white",
                      name: "fontcolor",
                      value: "#fff"
                    },
                    domProps: { checked: _vm._q(_vm.fontColor, "#fff") },
                    on: {
                      change: function($event) {
                        _vm.fontColor = "#fff"
                      }
                    }
                  }),
                  _c("label", { attrs: { for: "font-white" } }, [
                    _c("b", [
                      _c("span", { staticClass: "white" }),
                      _vm._v(" " + _vm._s(_vm.$t("white")) + " ")
                    ])
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.fontColor,
                        expression: "fontColor"
                      }
                    ],
                    attrs: {
                      type: "radio",
                      id: "font-grey",
                      name: "fontcolor",
                      value: "#ccc"
                    },
                    domProps: { checked: _vm._q(_vm.fontColor, "#ccc") },
                    on: {
                      change: function($event) {
                        _vm.fontColor = "#ccc"
                      }
                    }
                  }),
                  _c("label", { attrs: { for: "font-grey" } }, [
                    _c("b", [
                      _c("span", { staticClass: "grey" }),
                      _vm._v(" " + _vm._s(_vm.$t("gray")) + " ")
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "review-share boxarea" }, [
                _c("div", { staticClass: "review-sns" }, [
                  _c("h2", [_vm._v(_vm._s(_vm.$t("share")))]),
                  _c(
                    "div",
                    [
                      _c(
                        "ShareNetwork",
                        {
                          attrs: {
                            network: "Twitter",
                            url: "https://reviewmind.com/company",
                            title: "Say hi to ReviewMind"
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/reviewmind/share-twitter.png"),
                              alt: "SNS"
                            }
                          }),
                          _c("span", [_vm._v("twitter")])
                        ]
                      ),
                      _c(
                        "ShareNetwork",
                        {
                          attrs: {
                            network: "Facebook",
                            url: "https://reviewmind.com/company",
                            title: "Say hi to ReviewMind"
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/reviewmind/share-facebook.png"),
                              alt: "SNS"
                            }
                          }),
                          _c("span", [_vm._v("facebook")])
                        ]
                      ),
                      _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              return _vm.handleShare("naver")
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/reviewmind/share-naver.png"),
                              alt: "SNS"
                            }
                          }),
                          _c("span", [_vm._v("naver")])
                        ]
                      ),
                      _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              return _vm.handleShare("kakao")
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/reviewmind/share-kakao.png"),
                              alt: "SNS"
                            }
                          }),
                          _c("span", [_vm._v("kakao")])
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "marketing-right boxarea" }, [
              _c("h2", [
                _vm._v(" " + _vm._s(_vm.$t("review_marketing_preview")) + " "),
                _c("a", { on: { click: _vm.print } }, [
                  _vm._v(_vm._s(_vm.$t("save_image")))
                ])
              ]),
              _vm.reviewData.length > 0
                ? _c(
                    "div",
                    {
                      ref: "print",
                      staticClass: "review-preview",
                      style: { background: _vm.colorObj.hex8 }
                    },
                    [
                      _c("div", { staticClass: "preview-summary" }, [
                        _vm._m(0),
                        _c("h2", { style: { color: _vm.fontColor } }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.reviewData[0].review_source) +
                              " " +
                              _vm._s(_vm.reviewData[0].productName) +
                              " " +
                              _vm._s(_vm.$t("preview_summary")) +
                              " "
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "reviewChart" },
                          [
                            _c("Bar-Chart", {
                              attrs: {
                                title: "리뷰 현황 요약",
                                subTitle2: "533",
                                fontColor: _vm.fontColor,
                                series: _vm.series
                              }
                            }),
                            _c("div", [
                              _c("h3", { style: { color: _vm.fontColor } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      (
                                        _vm.totalRating / _vm.totalCount
                                      ).toFixed(2)
                                    ) +
                                    " "
                                )
                              ]),
                              _vm._m(1)
                            ])
                          ],
                          1
                        )
                      ]),
                      _c(
                        "ul",
                        _vm._l(_vm.reviewData, function(data, i) {
                          return _c("li", { key: i }, [
                            _c(
                              "h2",
                              [
                                _vm._v(" " + _vm._s(data.rating) + " "),
                                _vm._l(data.rating, function(item, j) {
                                  return _c("i", {
                                    key: j,
                                    staticClass: "fas fa-star",
                                    staticStyle: { margin: "1px" }
                                  })
                                })
                              ],
                              2
                            ),
                            data.review_source
                              ? _c("span", [
                                  _vm._v("via " + _vm._s(data.review_source))
                                ])
                              : _vm._e(),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm
                                    .moment(data.review_date)
                                    .format("YYYY-MM-DD")
                                )
                              )
                            ]),
                            _c("p", [
                              _vm._v(" " + _vm._s(data.review) + " "),
                              _c("span", [_vm._v(_vm._s(data.reviewer_name))])
                            ])
                          ])
                        }),
                        0
                      ),
                      _c("div", { attrs: { id: "bottomSensor" } })
                    ]
                  )
                : _c("div", { staticClass: "nodata" }, [
                    _vm._v(_vm._s(_vm.$t("select_comment")))
                  ])
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "powerby" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/reviewmind/powerby.png"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", { staticClass: "fas fa-star" }),
      _c("i", { staticClass: "fas fa-star" }),
      _c("i", { staticClass: "fas fa-star" }),
      _c("i", { staticClass: "fas fa-star" }),
      _c("i", { staticClass: "far fa-star" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }