var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("VueApexCharts", {
    ref: "apexChart",
    attrs: {
      type: "bar",
      options: _vm.chartOptions,
      series: _vm.series,
      height: "100%",
      width: "100%"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }